.articleContainer {
    padding-top: 50px;
    text-align: center;
}

.cover {
    max-width: 60%;
    height: auto;
    border-radius: 50px;
    border: 7px #c18794 solid;
    margin-bottom: 17px;
}

.title {
    font-size: 80px;
    padding-bottom: 5px;
}

.date {
    color: grey;
    padding-bottom: 17px;
}

.contentContainer {
    text-align: left;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 50px;
}

p {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 150%;
}

.button {
    margin-bottom: 50px;
    font-size: 300%;
    border-radius: 50px;
}