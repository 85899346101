.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    padding-left: 70px;
    padding-right: 70px;
    justify-content: center;
    align-items: center;
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
}

.teamname {
    padding-bottom: 20px;
    padding-top: 10px;
}

.driver1, .driver2, .driver3 {
    padding-bottom: 15px;
}

.teamcontainer {
    border-radius: 30px;
    padding: 40px;
    text-align: center;
}

.teamimage {
    width: 52px;
    height: 55px;
}