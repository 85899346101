.video-container {
    padding: 60px 0;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selected-video-container {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    row-gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
}

.video-frame {
    width: 321px;
    height: 180px;
    border: 5px solid #c18794;
}

/* If the screen size is 1023px wide or less, hide the element */
@media only screen and (max-width: 1023px) {
    .selected-video-container {
        display: flex;
    flex-direction: row;
    column-gap: 24px;
    row-gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
    }

    .video-frame {
        width: 321px;
    height: 180px;
    border: 5px solid #c18794;
    }
  
  }

  /* If the screen size is 799px wide or less, hide the element */
@media only screen and (max-width: 799px) {
    .selected-video-container {
        display: flex;
    flex-direction: row;
    column-gap: 24px;
    row-gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
    }

    .video-frame {
        width: 321px;
    height: 180px;
    border: 5px solid #c18794;
    }
  }