@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  font-weight: 900;
}

h1 {
  font-size: 90px;
  font-weight: 900;
}

h2 {
  font-size: 32px;
  font-weight: 900;
}

h3 {
  font-size: 24px;
  font-weight: 900;
}

h4 {
  font-size: 18.72px;
  font-weight: 900;
}

h5 {
  font-size: 16px;
  font-weight: 900;
}

h6 {
  font-size: 13.28px;
  font-weight: 900;
}

p {
  font-size: 16px;
  font-weight: 900;
}

hr {
  border-top: 3px solid white;
  width: 74px;
  background-color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
background-color: #C18794;
color: black;
font-family: "Inter", sans-serif;
font-size: 16px;
border-radius: 25px;
padding: 13px 16px;
text-transform: uppercase;
font-weight: 900;
border: none;
cursor: pointer;
}

button:hover {
  transition: 0.3s;
  background-color: white;
}

input {
  background: none;
  color: white;
  border-radius: 25px;
  border: 1px solid #c18794;
  font-size: 16px;
  padding: 8px 16px;
  font-family: "Inter", sans-serif;
}

select {
  background: black;
  color: white;
  border-radius: 25px;
  border: 1px solid #c18794;
  font-size: 16px !important;
  padding: 8px 16px;
  font-family: "Inter", sans-serif;
}


::-webkit-input-placeholder {
  /* Edge */
  color: white;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
}

::placeholder {
  color: white;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
}

header {
  position: fixed;
  z-index: 999;
  width: 100%;
  margin-top: -84px;
}

main {
  position: relative;
  z-index: 998;
  margin: 84px 84px 0 84px;
  width: auto;

}

/* If the screen size is 1023px wide or less, hide the element */
@media only screen and (max-width: 1023px) {
  main {
    margin: 84px 0 60px 0;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18.72px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 13.28px;
  }

  h6 {
    font-size: 10.72px;
  }

  button {
    font-size: 13.28px;
  }

}

/* If the screen size is 799px wide or less, hide the element */
@media only screen and (max-width: 799px) {
  header {
    
    margin-top: -67px;
  }

  main {
    margin: 67px 0 0 0;
  }
}
