.race-format-container {
    color: white;
    padding: 60px 0px;
}

.race-format-heading {
font-size: 32px;
}

.race-format-subheading {
    
}

.race-format-text {
    
}